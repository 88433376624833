import React, { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdClose, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { APIRequest, ApiUrl } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../app/slice/CartSlice";
import Modal from "react-modal";
import axios from "axios";

export const CartDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultAddress = useSelector((state) => state.defaultAddress);
  const CartData = useSelector((state) => state.cart.cartData);
  const StoreData = useSelector((state) => state.user.storeData);

  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [Address, setAddress] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(defaultAddress);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null); // "ASAP" or "CUSTOM"
  const [deliveryTime, setDeliveryTime] = useState("");
  const [selectedTip, setSelectedTip] = useState(0);
  const [allTips, setAllTips] = useState([]);
  const [deliveryCharge, setDeliveryCharge] = useState([]);
  const [coupons, setcoupons] = useState([]);
  // const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState(0);

  const [deliveryAmount, setDeliveryAmount] = useState(0);


  // for coupon
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState('');
  const [selectedCouponActive, setselectedCouponActive] = useState('');
  const [activatedCupon, setactivatedCupon] = useState('');



  useEffect(() => {
    setactivatedCupon("")
    setselectedCouponActive("")
    setSelectedCoupon("")
  }, [CartData])

  const handleInputFocus = () => {
    setModalOpen(true);
    setactivatedCupon("")
  };

  const handleCouponSelect = (coupon) => {
    setselectedCouponActive(coupon)
    setSelectedCoupon(coupon?.details?.name);
    setModalOpen(false);
  };

  // for coupon close

  // const API_KEY = `AIzaSyDwwL3sIm-b4s_qTweJ  5u2nHSjmO7Y7RH8`;
  const isInvalidDeliveryAmount =
    isNaN(Number(deliveryAmount)) || deliveryAmount === "Not";

  useEffect(() => {
    // calculateDistance();
    haversineDistanceInMiles();
  }, [selectedAddressId]);

  // console.log("origin", origin);
  // console.log("destination", destination);

  const haversineDistanceInMiles = () => {
    const toRad = (value) => (value * Math.PI) / 180;

    const lat1 = toRad(StoreData?.location?.coordinates[0]);
    const lon1 = toRad(StoreData?.location?.coordinates[1]);
    const lat2 = toRad(
      selectedAddressId &&
      selectedAddressId?.location &&
      selectedAddressId?.location?.coordinates &&
      selectedAddressId?.location?.coordinates[0]
    );
    const lon2 = toRad(
      selectedAddressId &&
      selectedAddressId?.location &&
      selectedAddressId?.location?.coordinates &&
      selectedAddressId?.location?.coordinates[1]
    );

    const R = 3958.8; // Radius of the Earth in miles

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in miles
    setDistance(distance.toFixed(0));
  };

  // Define initial state values
  const initialState = {
    quantity: 1,
    isLoading: false,
    Address: [],
    selectedAddressId: null,
    selectedTip: 0,
    selectedPaymentMethod: null,
    deliveryType: null, // 'ASAP' or 'CUSTOM'
    deliveryDateTime: new Date(),
  };
  const [state, setState] = useState(initialState);

  const GetCart = () => {
    setactivatedCupon("")
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getCart}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        dispatch(setCart(res?.data[0]));
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };
  const GetAddress = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getAddress}`,
      method: "get",
    };

    APIRequest(
      config,
      (res) => {
        const addresses = res?.data || [];
        setAddress(addresses);
        setIsLoading(false);

        // Get stored default address from local storage if available
        const storedAddressId = localStorage.getItem("defaultAddressId");

        // Find the default address either from local storage or from fetched data
        let defaultAddress;

        if (storedAddressId) {
          // Find the address in the fetched data that matches the stored address ID
          defaultAddress = addresses.find(
            (addr) => addr._id === storedAddressId
          );
        }

        // If no default address from local storage, find a new default address from fetched data
        if (!defaultAddress) {
          defaultAddress = addresses.find((addr) => addr.isDefault);
        }

        // If no default address found, set the first address (0th index) as default
        if (!defaultAddress && addresses.length > 0) {
          defaultAddress = addresses[0]; // First address
        }

        // Set the selected address if a default or 0th index address is found
        if (defaultAddress) {
          setSelectedAddressId(defaultAddress._id);
          localStorage.setItem("defaultAddressId", defaultAddress._id);
        } else {
          // Handle case where no address is available
          toast.info("No address available. Please add an address.");
        }
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };
  const getUserCupon = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getCuponForUser}`,
      method: "get",
    };

    APIRequest(
      config,
      (res) => {
        const coupons = res?.data || [];
        console.log('asdada',coupons);
        
        setcoupons(coupons)
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const UpdateCart = (productId, storeId, quantity) => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.addCart}`,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        toast.success(res?.message);
        // dispatch(setCart(res?.created))
        GetCart();
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const RemoveProduct = (id) => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.deleteProductCart}`,
      method: "post",
      body: {
        productId: id,
      },
    };
    APIRequest(
      config,
      (res) => {
        toast.success(res?.message);
        GetCart();
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };
  const handlePlaceOrder = (id) => {
    //  Validation checks
    if (!selectedAddressId) {
      toast.error("Please select a delivery address.");
      return;
    }
    if (!selectedPaymentMethod) {
      toast.error("Please select a payment method.");
      return;
    }
    if (deliveryType === "CUSTOM" && deliveryTime === "instant") {
      toast.error("Please select a delivery date and time.");
      return;
    }
    // if (state.selectedTip === null) {
    //   toast.error("Please select a tip amount.");
    //   return;
    // }

    setIsLoading(true);
    let config = {
      url: `${ApiUrl.placeOrder}`,
      method: "post",
      body: {
        cartId: CartData?._id,
        addressId: selectedAddressId?._id,
        storeId: StoreData?._id,
        // "couponId":"669a3be224348e762d622311",
        deliveryCharge: deliveryAmount,
        deliveryTime: deliveryTime, // instant  // it will be the String
        // "transactionId":"66961e9551bbfe0e5731c24c",
        isWallet: selectedPaymentMethod === "Wallet" ? true : false, // false , true
        tip: selectedTip, // it will only number
      },
    };
    console.log("Wallet", config);
    APIRequest(
      config,
      (res) => {
        toast.success(res?.message);
        GetCart();
        resetForm();
        navigate("/order-successfull");
        // Clear the cart after a successful order placement
        dispatch(setCart([]));
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const proceed_to_pay = () => {
    //  Validation checks
    if (!selectedAddressId) {
      toast.error("Please select a delivery address.");
      return;
    }
    if (!selectedPaymentMethod) {
      toast.error("Please select a payment method.");
      return;
    }
    if (deliveryType === "CUSTOM" && deliveryTime === "instant") {
      toast.error("Please select a delivery date and time.");
      return;
    }
    if (!deliveryAmount && deliveryAmount === 0) {
      toast.error("Sorry We Can't Provide Delivery !! You are out of Range.");
      return;
    }

    if (deliveryAmount === 0) {
      toast.error("Sorry We Can't Provide Delivery !! You are out of Range.");
    } else {
      setIsLoading(true);
      let config = {
        url: ApiUrl?.transaction_create,
        method: "post",
        body: {
          cartId: CartData?._id,
          deliveryCharge: deliveryAmount,
          tip: selectedTip,
        },
      };
      console.log("Paypal", config);
      APIRequest(
        config,
        (res) => {
          // console.log("API Response:", res);
          localStorage.setItem(
            "PaypalData",
            JSON.stringify({
              cartId: CartData?._id,
              deliveryCharge: deliveryAmount,
              tip: selectedTip,
              deleveryTime: deliveryTime,
              addresId: selectedAddressId?._id,
              storeId: StoreData?._id,
            })
          );

          toast.success(res?.message);
          resetForm();
          GetCart();
          // Clear the cart after a successful order placement
          dispatch(setCart([]));
          window.location.href = res?.data?.links[1].href;
          // localStorage.setItem(
          //   "cartId",
          //   JSON.stringify({
          //     cartId: CartData?._id,
          //     addressId: selectedAddressId?._id,
          //   })
          // );
          setIsLoading(false);
        },
        (err) => {
          // console.log("err", err);
          toast.error(err?.message);
          setIsLoading(false);
        }
      );
    }
    // alert("done");
  };

  const handlePayment = () => {
    if (selectedPaymentMethod === "Paypal") {
      proceed_to_pay();
    } else if (selectedPaymentMethod === "Wallet") {
      handlePlaceOrder();
    } else {
      toast.error("Please select a valid payment method.");
    }
  };

  const resetForm = () => {
    setState(initialState);
  };

  const getTip = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.getTip,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        if (!res?.error) {
          // console.log("tip data===============", res?.data);
          setIsLoading(false);
          setAllTips(res?.data);
        }
      },
      (err) => {
        setIsLoading(false);
        if (err?.error) {
          toast.error(err?.message);
        }
      }
    );
  };
  const getDeliveryCharge = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl?.getDeliveryCharge}/${StoreData?._id}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        if (!res?.error) {
          console.log("Delivery Charge data===============", res?.data);
          setDeliveryCharge(res?.data);
          setIsLoading(false);
        }
      },
      (err) => {
        setIsLoading(false);
        if (err?.error) {
          toast.error(err?.message);
        }
      }
    );
  };

  const handleTipChange = (amount) => {
    setSelectedTip(amount);
  };
  // console.log(selectedTip);

  const handleAddressChange = (addressId) => {
    setSelectedAddressId(addressId);
    // console.log("sadasdasdas@@@@", addressId);

    localStorage.setItem("defaultAddressId", addressId); // Save to local storage
  };

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e);
  };
  // console.log(selectedPaymentMethod);

  // Handle delivery type change
  const handleDeliveryTypeChange = (e) => {
    const selectedType = e.target.value;
    setDeliveryType(selectedType);

    if (selectedType === "ASAP") {
      setDeliveryTime("instant");
    } else {
      // Clear the delivery time when selecting "CUSTOM"
      setDeliveryTime("");
    }
    // console.log("DeliveryType", selectedType);
  };

  const today = new Date().toLocaleString("en-US", { weekday: "long" });
  useEffect(() => {
    const availableSlots = getTimeSlotsForDay(StoreData.workingDays, today);
    setAvailableTimeSlots(availableSlots);
    // console.log("Available Time Slots", availableSlots);
  }, [today, StoreData.workingDays]); // Re-run if StoreData or today's day changes

  // Handle custom time change
  const handleCustomTimeChange = (time) => {
    setDeliveryTime(time);
    setDeliveryType("CUSTOM");
    document.getElementById("exampleModal").click();
  };

  const totalAmount = CartData
    ? (
      CartData.superTotal -
      CartData.discount +
      Number(((CartData?.total * selectedTip) / 100).toFixed(2) || 0) +
      (isNaN(Number(deliveryAmount)) ? 0 : Number(deliveryAmount))
    ).toFixed(2)
    : 0;

  useEffect(() => {
    // Get the default address ID from local storage
    const storedAddressId = localStorage.getItem("defaultAddressId");
    if (storedAddressId) {
      setSelectedAddressId(storedAddressId);
    }
    GetCart();
    GetAddress();
    getTip();
    getDeliveryCharge();
    getUserCupon()
  }, []);

  // Utility to convert 12-hour format to 24-hour format
  const convertTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":");

    // Convert hours to an integer before using
    hours = parseInt(hours, 10);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    // Ensure hours and minutes are two digits by converting them to strings
    return `${hours.toString().padStart(2, "0")}:${minutes.padStart(2, "0")}`;
  };

  const convertTo12Hour = (timeStr) => {
    let [hours, minutes] = timeStr.split(":");
    const period = +hours < 12 ? "AM" : "PM";
    hours = +hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  // Function to generate 30-minute interval time slots
  const generateTimeSlots = (openingTime, closingTime) => {
    const slots = [];
    const today = new Date(); // Get today's date

    // Split the opening and closing times into hours and minutes
    const [openHour, openMinute] = openingTime.split(":").map(Number);
    const [closeHour, closeMinute] = closingTime.split(":").map(Number);

    // Set the current day's date with the opening and closing hours
    let startTime = new Date(today.setHours(openHour, openMinute, 0, 0));
    let endTime = new Date(today.setHours(closeHour, closeMinute, 0, 0));

    // console.log("startTime,endTime", startTime, endTime); // Should log valid times for today

    while (startTime < endTime) {
      const endSlotTime = new Date(startTime.getTime() + 30 * 60000); // Add 30 minutes
      if (endSlotTime <= endTime) {
        slots.push({
          startTime: convertTo12Hour(startTime.toTimeString().slice(0, 5)),
          endTime: convertTo12Hour(endSlotTime.toTimeString().slice(0, 5)),
        });
      }
      startTime = endSlotTime; // Move to the next 30-minute slot
    }

    return slots.map((slot) => ({
      time: `${slot.startTime}-${slot.endTime}`,
      value: `${slot.startTime}-${slot.endTime}`,
    }));
  };

  // Function to filter time slots based on store hours
  const getTimeSlotsForDay = (workingDays, dayName) => {
    const todayWorkingHours = workingDays.find(
      (day) => day.day === dayName && day.status === "Opened"
    );

    // console.log("todayWorkingHours", todayWorkingHours); // Logs today's working hours

    if (!todayWorkingHours) {
      // Store is closed today
      return [];
    }

    const openingTime = convertTo24Hour(todayWorkingHours.openingTime);
    const closingTime = convertTo24Hour(todayWorkingHours.closingTime);

    return generateTimeSlots(openingTime, closingTime);
  };

  const getAmountForDistance = (distance) => {
    // Sort ranges by startRange in ascending order
    const sortedRanges = deliveryCharge?.sort(
      (a, b) => a.startRange - b.startRange
    );

    // Find the applicable range
    for (let range of sortedRanges) {
      console.log(distance, range);

      if (distance >= range.startRange && distance <= range.endRange) {
        return range.amount;
      }
    }

    // Return a default value or message if no range matches
    // alert("You are  Out Of Range");
    return "Not";
  };
  useEffect(() => {
    let amount = distance && getAmountForDistance(distance);
    setDeliveryAmount(amount);
  }, [distance, deliveryAmount]);


  console.log("couponscouponscouponscoupons", selectedCouponActive);

  const validateCoupon = (coupon, amount) => {
    const { upto } = coupon.details;
    return amount >= 0 && amount <= upto;
  };

  const handleApplyCoupon = () => {
    const amount = parseFloat(totalAmount);
    if (selectedCouponActive) {
      if (validateCoupon(selectedCouponActive, amount)) {
        // setMessage("Th\e coupon is valid and can be applied.");
        toast.success("Valid coupon stored")
        setactivatedCupon(selectedCouponActive)
        console.log("Valid coupon stored:", selectedCouponActive);
      } else {
        toast.error("The coupon cannot be applied.");

        // setMessage("The coupon cannot be applied.");
      }
    } else {
      toast.error("please Select Coupon First");
    }


  };


  return (
    <section className="h-100 gradient-custom">
      <div className="container py-5">
        <div className="row d-flex justify-content-center my-4">
          <div className="col-md-8">
            <div className="card mb-4">
              <div className="card-header py-3">
                <h3 className="mb-0 fs-bold">Shopping Cart</h3>
              </div>
              {CartData?.products?.map((item, index) => (
                <div className="card-body">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-2">
                      <img
                        src={item?.productId?.images[0]?.url}
                        className="img-fluid rounded-3"
                        alt="Cotton T-shirt"
                      />
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <p className="lead fw-normal mb-2">
                        {item?.productId?.name}
                      </p>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-2 d-flex account">
                      <button
                        className="btn btn-link px-2 shadow-none"
                        onClick={() =>
                          item?.quantity > 0 &&
                          UpdateCart(
                            item?.productId?._id,
                            CartData?.storeId,
                            -1
                          )
                        }
                      >
                        <FaMinus style={{ color: "red" }} />
                      </button>
                      <input
                        id="form1"
                        min="0"
                        name="quantity"
                        value={item?.quantity}
                        type="number"
                        className="form-control form-control-sm border-0"
                        readOnly
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      />

                      <button
                        className="btn btn-link px-2 shadow-none"
                        onClick={() =>
                          UpdateCart(item?.productId?._id, CartData?.storeId, 1)
                        }
                      >
                        <FaPlus style={{ color: "red" }} />
                      </button>
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                      <h5 className="mb-0 fw-bold">${item?.basePrice}</h5>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                      <Link
                        href="#!"
                        className="text-danger"
                        onClick={() => RemoveProduct(item?.productId?._id)}
                      >
                        <MdDelete className="fs-3" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <p>
                  <strong>Prefer Deliver Address</strong>
                </p>
                {Address?.map((item, index) => (
                  <div className="d-flex align-items-center me-3">
                    <input
                      type="radio"
                      name="home"
                      id=""
                      className="me-1"
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                      onChange={() => handleAddressChange(item)}
                    />
                    <label className="fw-bold" style={{ cursor: "pointer" }}>
                      {item?.address}({item?.name})
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <p>
                  <strong>Preferred Delivery Time</strong>
                </p>
                <div className="d-flex align-items-center me-3">
                  <label
                    className="fw-bold d-flex align-items-center"
                    style={{
                      cursor: "pointer",
                    }}
                    onChange={handleDeliveryTypeChange}
                  >
                    <input
                      type="radio"
                      name="deliveryType"
                      value="ASAP"
                      checked={deliveryType === "ASAP"}
                      className="me-1"
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                    />
                    ASAP (Deliver as soon as possible)
                  </label>
                </div>
                <div
                  className="d-flex align-items-center me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <label
                    className="fw-bold d-flex align-items-center"
                    style={{
                      cursor: "pointer",
                    }}
                    onChange={handleDeliveryTypeChange}
                  >
                    <input
                      type="radio"
                      name="deliveryType"
                      value="CUSTOM"
                      checked={deliveryType === "CUSTOM"}
                      className="me-1"
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                    />
                    Schedule for later
                    {deliveryTime && deliveryType === "CUSTOM" && (
                      <span className="ms-2">({deliveryTime})</span>
                    )}
                  </label>
                  {/* {deliveryType === "CUSTOM" && (
                    <div className="ms-3"
                    >
                      <input
                        type="time"
                        onChange={handleCustomTimeChange}
                        value={deliveryTime} // Keep the selected time in the input
                        className="form-control"
                      />
                    </div>
                  )} */}
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div
                        class="modal-content"
                        style={{
                          width: "50vh",
                          height: "40vh",
                          overflow: "auto",
                          borderRadius: "0",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <div
                          class="modal-body"
                          style={{
                            padding: "0",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                position: "sticky",
                                top: "0",
                                right: "0",
                                backgroundColor: "white",
                              }}
                              className="d-flex justify-content-end "
                            >
                              <MdClose
                                className="fs-3"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="fs-5 fw-bold ps-4">
                              Delivery Time
                            </div>

                            <div className="pt-0 p-4 ">
                              {/* <h2>Select a Time Slot</h2>/ */}
                              <div className="time-slot-container">
                                {availableTimeSlots.map((slot) => (
                                  <div
                                    key={slot.value}
                                    className="d-flex gap-2 "
                                    style={{ cursor: "pointer" }}
                                  >
                                    <input
                                      type="radio"
                                      name="timeSlot"
                                      value={slot.value}
                                      checked={deliveryTime === slot.value}
                                      onChange={() =>
                                        handleCustomTimeChange(slot.value)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                    <label
                                      htmlFor={slot.value}
                                      className="time-slot"
                                    >
                                      {slot.time}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4 mb-lg-0">
              <div className="card-body">
                <p>
                  <strong>Payment Method</strong>
                </p>
                <div
                  className="col-sm-12 col-md-8 col-lg-6 d-flex align-items-center mb-3 p-2 rounded bg-light"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    id=""
                    className="me-2"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onChange={() => handlePaymentMethodChange("Paypal")}
                  />
                  <span className="fs-6  fw-bold">Paypal</span>
                </div>
                <div
                  className="col-sm-12 col-md-8 col-lg-6 d-flex align-items-center mb-3 p-2 rounded bg-light"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    id=""
                    className="me-2"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onChange={() => handlePaymentMethodChange("Wallet")}
                  />
                  <span className="fs-6 fw-bold">Wallet</span>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <p>
                  <strong>Tip</strong>
                </p>
                <div className="d-flex">
                  {allTips.length > 0 ? (
                    allTips.map((tip, index) => (
                      <div
                        key={index}
                        className="col-2 d-flex align-items-center me-3 p-2 rounded bg-light"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      >
                        <input
                          type="radio"
                          name="tip"
                          id={`tip${index}`}
                          className="me-1"
                          style={{
                            width: "15px",
                            height: "15px",
                            cursor: "pointer",
                          }}
                          value={tip.amount}
                          onChange={() => handleTipChange(tip.amount)}
                          checked={selectedTip === tip.amount}
                        />
                        <label
                          className="fw-bold"
                          htmlFor={`tip${index}`}
                          style={{ cursor: "pointer" }}
                        >
                          {tip.amount}%
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>No tips available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">

            {/* coupon */}
            <div className="card mb-4">
              <div className="card-body p-4 d-flex flex-row">
                <div data-mdb-input-init className="form-outline flex-fill">
                  {/* <input
                    type="text"
                    id="form1"
                    className="form-control form-control-lg shadow-none"
                    placeholder="Discount Code"
                    style={{ border: "1px solid red" }}
                  /> */}
                  <input
                    type="text"
                    id="form1"
                    className="form-control form-control-lg shadow-none"
                    placeholder="Discount Code"
                    style={{ border: '1px solid red' }}
                    value={selectedCoupon}
                    onFocus={handleInputFocus}
                    readOnly
                  />
                </div>
                <button
                  type="button"

                  onClick={() => handleApplyCoupon()}
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className="btn btn-outline-danger btn-lg ms-3"
                >
                  Apply
                </button>
              </div>
            </div>


            {isModalOpen && (
              <div className="modal show d-block " style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog modal_coupon">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Select a Coupon</h5>
                      <button type="button" className="btn-close" onClick={() => setModalOpen(false)}></button>
                    </div>
                    <div className="modal-body">
                      <ul className="list-group">
                        {coupons?.coupons?.map((coupon, index) => (
                          <li
                            key={index}
                            className="list-group-item"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCouponSelect(coupon)}
                          >
                            {coupon?.details?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="card mb-4">
              <div className="card-header py-3">
                <h5 className="mb-0">Bill Details</h5>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    SubTotal
                    <span>${CartData && CartData.superTotal?.toFixed(2)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                    <span>Shipping Fee</span>
                    <>
                      <span>${deliveryAmount}</span>
                    </>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                    Discount
                    <span>${activatedCupon?.details?.discount ? (CartData && CartData.discount + activatedCupon?.details?.discount).toFixed(2) : CartData && CartData.discount.toFixed(2)}</span>
                  </li>
                  {/* <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    Sales Tax
                    <span>$0.00</span>
                  </li> */}
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    Tip
                    <span>
                      $
                      {((CartData?.total * selectedTip) / 100).toFixed(2) ||
                        "0.00"}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total</strong>
                    </div>
                    <span>
                      <strong>${activatedCupon?.details?.discount ? (totalAmount - activatedCupon?.details?.discount) : totalAmount}</strong>
                    </span>
                  </li>
                </ul>

                <button
                  type="button"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className="btn btn-danger btn-lg btn-block w-100 shadow-none"
                  onClick={handlePayment}
                  disabled={isInvalidDeliveryAmount || isLoading} // Disable if deliveryAmount is invalid or loading
                >
                  {isLoading ? "Processing..." : "Proceed to Pay"}
                </button>

                {isInvalidDeliveryAmount && (
                  <p className="text-danger mt-2">
                    Sorry, we are not delivering to this area.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
